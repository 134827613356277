.container{
    margin-top: calc(40rem / 29);
    text-align: center;
    text-transform: uppercase;
    font-size: calc(12rem / 29);
    line-height: calc(15rem / 29);
    letter-spacing: calc(1rem / 29);
    font-weight: 700;
    padding: calc(17rem / 29);
    border-radius: calc(100rem / 29);
    background-color: var(--theme-detailColor);
    color: var(--theme-textColor2);
    cursor: pointer;
}
