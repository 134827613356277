.label{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background: white;
    box-shadow: 0 0 15px 15px rgba(0,0,0,.05);
    transform: translate(-50%, -50%);
    z-index: 2;
}
.label_text{
    position: absolute;
    left:0;
    top:0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
.label_text:active{
    filter: brightness(1.2);
}
.dot{
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 11px;
    background: #A50C30;
}
.line{
    position: absolute;
    z-index: 1;
}
.text{
    --minBoxSize: 5em;
    border-radius: 5px;
    position: absolute;
    color: white;
    left: 37px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: rgba(255,255,255,0.3);
}
.desc{
    min-width: var(--minBoxSize);
    font-size: 22px;
    font-weight: 500;
    line-height: 1em;
    letter-spacing: -0.23px;
    color: #1C1A1A;
}
.title{
    min-width: var(--minBoxSize);
   font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1em;
    letter-spacing: 1px;
    color: #1C1A1A;
    opacity: 0.5;
}
.desc:before{
    position: relative;
    content: "";
    background-color: #A50B30;
    height: 5px;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 25%;
    display: flex;
    color: #A50B30;
    font-size: 68px;
}