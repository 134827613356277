.container{
    height: 100%;
    width: 100%;
}
.swipe {
    overflow: hidden;
    visibility: hidden;
    position: relative;
}
.swipe_wrap {
    overflow: hidden;
    position: relative;
}
.swipe_wrap_item {
    float: left;
    width: 100%;
    position: relative;
}
.item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.no_content_container{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}