.container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.container img {
    object-fit: contain;
    height: 100vh;
}
.swipe {
    overflow: hidden;
    visibility: hidden;
    position: relative;
}
.swipe_wrap {
    overflow: hidden;
    position: relative;
}
.swipe_wrap_item {
    float: left;
    width: 100%;
    position: relative;
}
