.container {
 display: grid;
 margin: 50px 0;
 /*grid-gap:15px;*/
 grid-gap: calc(15rem / 29);
 justify-content: center;
}
.item {
 position: relative;
 display: flex;
 justify-content: center;
 align-items: center;
}
.item:active{
 filter: brightness(1.2);
}
.item:after{
 position: absolute;
 left: 0;
 width: 100%;
 height: 100%;
 top: 0;
 content: "";
 background: linear-gradient(to bottom, rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%);
}
img {
 height: 100%;
 width: 100%;
 object-fit: cover;
}
.loader_container{
 position: absolute;
 left: 50%;
 top: 50%;
 transform: translate(-50%, -50%);
 background-color: var(--theme-bgColor);
}