.language-flag {
    padding: 8px;
    border-radius: 50%;
}
.language-item {
    flex: 1;
    max-width: fit-content;
    padding: 15px;
}
.language-flag.selected, .language-item:active .language-flag{
    padding: 5px;
    border: 3px solid #a5000a;
}
.language-item-text {
    margin-top: 10px;
    text-align: center;
    font-size: 20px;
}
.language-flag.selected + .language-item-text{
    font-weight: 700;
}
.language-item:active .language-item-text{
    font-weight: 700;
}