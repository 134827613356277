.container{
    display: flex;
    flex-direction: column;
    border-radius: calc(35rem / 29);
    padding: calc(9rem / 29) calc(20rem / 29);
    font-size: calc(14rem / 29);
    font-weight: 700;
}
.item{
    display: flex;
    justify-content: flex-end;
    margin: 3px 0;
    pointer-events: none;
}
.type{
    font-weight: 300;
    text-transform: lowercase;
    pointer-events: none;
}
.name{
    padding-left: 5px;
    font-style: italic;
    pointer-events: none;
}
