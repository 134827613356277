.container{
    padding: calc(10rem / 29) calc(20rem / 29);
    border-radius: calc(calc(10rem / 29) * 2);
    border: 2px solid var(--theme-bgColor2);
    display: flex;
    justify-content: center;
    align-items: center;
}
.item{
    width: max-content;
    color: var(--theme-textColor2);
    font-weight: 400;
    font-size: calc(16rem / 29);
    line-height: calc(22rem / 29);
}
