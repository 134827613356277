.container {
    /*margin: 15px;*/
    margin: calc(15rem / 29);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    touch-action: pan-x;
}
.container:active{
    filter: brightness(1.2);
}
.circle {
    position: relative;
    padding: 7vw;
    border-radius: 50%;
    background-color: var(--theme-textColor);
}
.circle svg{
    width: 3.5vw;
    height: 3.5vw;
}
.text {
    /*margin-top: 44px;*/
    margin-top: calc(44rem / 29);
    /*font-size: 22px;*/
    font-size: calc(22rem / 29);
    /*line-height: 27px;*/
    line-height: calc(27rem / 29);
    letter-spacing: -0.12px;
    font-weight: 500;
    text-align: center;
    color: var(--theme-textColor);
}
.img {
    fill: var(--theme-bgColor);
    position: absolute;
    transform: translate(-50%, -42%);
}
.container img{
    height: 14vw;
    width: 14vw;
}