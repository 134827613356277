.container{
    touch-action: none;
    -ms-touch-action: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.slider_menu{
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.header1 {
    margin: 0;
    font-weight: 300;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.25px;
}
.color_theme_menu {
  flex: 0 0 auto;
}
.langIcon{
    flex: 0 0 auto;
}
.langIcon:active{
    filter: brightness(1.2);
}
.header_container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 2;
}
.form{
    flex: 1;
    display: flex;
    align-items: flex-end;
    z-index: 2;
}
.background_image{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.background_image img{
    object-fit: cover;
}
.background_image::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.5) 100%);
    z-index: 1;
}
