.container{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.video {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.video_container{
    position: relative;
    width: 100%;
    height: 100%;
}
.overlay{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 1%, rgba(0,0,0,0.65) 50%, rgba(0,0,0,0.75) 80%, rgba(0,0,0,0.8) 100%);
}
.overlay svg{
    height: 50px;
    width: 50px;
    fill: var(--theme-textColor);
    color: var(--theme-textColor);
    cursor: pointer;
}
.overlay_progress_container{
    display: flex;
    margin: 5px 80px;
    justify-content: space-around;
}
.overlay_progress_bg:after{
    position: absolute;
    content: '';
    top: -25px;
    height: 55px;
    width: 100%;
}
.overlay_controls_container{
    display: flex;
    margin: 20px 80px;
    justify-content: space-between;
}
.overlay_controls_container div:active{
    filter: brightness(1.4);
}
.overlay_time_container{
    display: flex;
    margin: 0 80px;
    justify-content: space-between;
    align-items: flex-end;
}
.overlay_progress_bg{
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 10px;
    background-color: var(--theme-textColor);
    cursor: pointer;
}
.overlay_progress_value{
    position: absolute;
    border-radius: inherit;
    background-color: var(--theme-detailColor);
    transition: width 0.5s ease;
    height:100%;
    left: 0;
    width: 1%;
}