.language-overlay-content {
    background-color: #FFF;
    position: absolute;
    color: #000;
    padding: 0 50px;
    /*
    width: 550px;
    */
    height: 300px;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.language-overlay-header {
    text-align: center;
    padding: 30px;
    font-size: 30px;
    letter-spacing: 0.05em;
}
.language-close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 35px;
    transform: scale(1.3);
}
.language-spacer {
    height: 0;
    margin: 0 20px;
    border: 1px solid #EEE;
}
.language-flag-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    -webkit-overflow-scrolling: touch;
}
.language-overlay-bg-filter {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    z-index: 5;
}
