.container{
    display: flex;
    flex-direction: column;
    height:100%;
    width:100%;
}
.image_wrapper{
    position: relative;
    height: 100%;
}
.title, .desc {
    /*font-size: 24px;*/
    font-size: calc(24rem / 29);
    letter-spacing: 0;
    line-height: 28px;
    font-weight: 700;
}
.title, .play_title{
    margin-bottom: 7px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.desc, .play_desc{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.text{
    position: absolute;
    padding: 19px 30px;
    left: 0;
    top: 0;
    z-index: 1;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
}
.image{
    display: block;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}
.play_filter{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.7) 100%);
}
.play_filter_overlay{
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 80px;
}
.play_text_container{
    display: flex;
    flex-direction: column;
    margin-right: 35px;
}
.play_title{
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    color: var(--theme-textColor);
}
.play_desc{
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: var(--theme-textColor);
    opacity: 0.7;
}
.play_credit{
    margin-top: 33px;
}
.play_icon svg{
    height: 80px;
    width: 80px;
}
