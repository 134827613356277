body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body{
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: 'Apercu Pro';
  font-weight: 300;
  src: url('./fonts/ApercuPro-Light.eot'),
  url('./fonts/ApercuPro-Light.ttf'),
  url('./fonts/ApercuPro-Light.svg');
}
@font-face {
  font-family: 'Apercu Pro';
  font-weight: 400;
  src: url('./fonts/ApercuPro.eot'),
  url('./fonts/ApercuPro.woff'),
  url('./fonts/ApercuPro.ttf'),
  url('./fonts/ApercuPro.svg');
}
@font-face {
  font-family: 'Apercu Pro';
  font-weight: 400;
  font-style: italic;
  src: url('./fonts/ApercuPro-Italic.eot'),
  url('./fonts/ApercuPro-Italic.woff'),
  url('./fonts/ApercuPro-Italic.ttf'),
  url('./fonts/ApercuPro-Italic.svg');
}
@font-face {
  font-family: 'Apercu Pro';
  font-weight: 500;
  src: url('./fonts/ApercuPro-Medium.eot'),
  url('./fonts/ApercuPro-Medium.woff'),
  url('./fonts/ApercuPro-Medium.ttf'),
  url('./fonts/ApercuPro-Medium.svg');
}
@font-face {
  font-family: 'Apercu Pro';
  font-weight: 500;
  font-style: italic;
  src: url('./fonts/ApercuPro-MediumItalic.eot'),
  url('./fonts/ApercuPro-MediumItalic.woff'),
  url('./fonts/ApercuPro-MediumItalic.ttf'),
  url('./fonts/ApercuPro-MediumItalic.svg');
}
@font-face {
  font-family: 'Apercu Pro';
  font-weight: 700;
  src: url('./fonts/ApercuPro-Bold.eot'),
  url('./fonts/ApercuPro-Bold.woff'),
  url('./fonts/ApercuPro-Bold.ttf'),
  url('./fonts/ApercuPro-Bold.svg');
}
@font-face {
  font-family: 'Apercu Pro';
  font-weight: 700;
  font-style: italic;
  src: url('./fonts/ApercuPro-BoldItalic.eot'),
  url('./fonts/ApercuPro-BoldItalic.woff'),
  url('./fonts/ApercuPro-BoldItalic.ttf'),
  url('./fonts/ApercuPro-BoldItalic.svg');
}