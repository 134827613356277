.container {
    position: absolute;
    top: 0;
    left: 0;
    color: #FFF;
    /*margin: 25px 35px;*/
    /*padding: 12px 25px;*/
    margin: calc(25rem / 29) calc(35rem / 29);
    padding: calc(12rem / 29) calc(25rem / 29);
    background-color: var(--theme-detailColor);
    /*border-radius: 35px;
    font-size: 0.8em;
    letter-spacing: 1px;
    line-height: 0.6em;*/
    border-radius: calc(35rem / 29);
    font-size: calc(16rem / 29);
    letter-spacing: calc(1rem / 29);
    line-height: calc(12rem / 29);
}