.header-row-fullscreen-container {
    display: flex;
    /*margin: 50px;*/
    margin: calc(50rem / 29);
    justify-content: flex-end;
}
.header-row-fullscreen-item {
    position: relative;
    /*margin: 5px;
    padding: 35px;*/
    margin: calc(5rem / 29);
    padding: calc(35rem / 29);
    background-color: #FFF;
    border-radius: 50%;
    z-index: 5;
}
.header-row-fullscreen-item:active{
    filter: brightness(3);
}
.header-row-fullscreen-item.info{
    background-color: transparent;
    border: calc(2rem / 29) solid white;
    font-size: calc(20rem / 29);
}
.header-row-fullscreen-item svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.1);
    width: calc(26rem / 29);
    height: calc(26rem / 29);
}
.info-item {
    position: absolute;
    color: #FFF;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.7);
}