/* RESETS */
* {
    touch-action: manipulation;
    -ms-touch-action: manipulation;
    font-family: 'Apercu Pro', sans-serif;
}
:root {
    --lightDelta: 6%;
    --bgHue: 0;
    --bgSat: 3.7%;
    --bgLight: 10.6%;
    --textHue: 345;
    --textSat: 47.1%;
    --textLight: 93.3%;
    --detailHue: 346;
    --detailSat: 86.4%;
    --detailLight: 34.7%;
    --theme-bgColor: hsl(var(--bgHue), var(--bgSat), var(--bgLight));
    --theme-bgColor2: hsl(var(--bgHue), var(--bgSat), calc(var(--bgLight) + var(--lightDelta)));
    --theme-bgColor-altBlock: rgba(226,226,226, 0.05);
    --theme-textColor: hsl(var(--textHue), var(--textSat), var(--textLight));
    --theme-textColor2: hsl(var(--textHue), var(--textSat), calc(var(--textLight) - var(--lightDelta)));
    --theme-detailColor: hsl(var(--detailHue), var(--detailSat), var(--detailLight));
    --list-spacing: calc(10rem / 29);
    /*--standard-hmargin: 83px;
    --standard-vmargin: 64px;*/
    --standard-hmargin: calc(83rem / 29);
    --standard-vmargin: calc(64rem / 29);
    --standard-h-indent-padding: calc(150rem / 29);
    --scrollbar-height: 4px;
    --scrollbar-padding: 16px;
    --menu-hmargin: 49px;
    --menu-vmargin: 86px;
    --mapimage-arrow-size: 10px;

    /* FONT REM CONVERSIONS */
    font-size: calc(1vw + 1vh + .5vmin);
    --pixels-28: 0.973rem;
    --pixels-15: 0.52rem;
    --pixels-40: 1.3rem;
}
.theme-light {
    --lightDelta: 10%;
    --bgHue: 0;
    --bgSat: 0%;
    --bgLight: 93.3%;
    --textHue: 0;
    --textSat: 0%;
    --textLight: 31.4%;
    --detailHue: 346;
    --detailSat: 88.4%;
    --detailLight: 34.7%;
    --theme-bgColor: hsl(var(--bgHue), var(--bgSat), var(--bgLight));
    --theme-bgColor2: hsl(var(--bgHue), var(--bgSat), calc(var(--bgLight) + var(--lightDelta)));
    --theme-bgColor-altBlock: rgba(226, 226, 226, 0.95);
    --theme-textColor: hsl(var(--textHue), var(--textSat), var(--textLight));
    --theme-textColor2: hsl(var(--textHue), var(--textSat), calc(120% - var(--textLight)));
    --theme-detailColor: hsl(var(--detailHue), var(--detailSat), var(--detailLight));
}

html {
  background-color: var(--theme-bgColor);
    -webkit-tap-highlight-color: transparent;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome 6.0+, Safari 3.1+, Edge & Opera 15+ */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* IE 10+ and Edge */
    user-select: none;         /* Non-prefixed version, */
}
html, body, #root, .App{
    height: 100vh;
    width: 100vw;
}
.App {
    background-color: var(--theme-bgColor);
    color: var(--theme-textColor);
    display: flex;
}
a {
  text-decoration: none;
}
h1 {
  font-weight: 700;
}
.disabled {
    opacity: 0.6;
    cursor: unset;
    filter: grayscale(1);
}
.filter-disabled:after{
    background: unset;
}
.page {
  position: relative;
  /*height: calc(100vh - calc(64*2px));*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100vw
    - calc(var(--standard-hmargin) * 2));
    height: calc(100vh
    - calc(var(--standard-vmargin) * 2));
    padding: var(--standard-vmargin) var(--standard-hmargin);
    animation: fade 400ms ease-in 0ms;
    overflow-x: hidden;
    touch-action: none;
}
.page-article{
    overflow-y: auto;
    padding: 0;
    touch-action: pan-y;
    width: 100vw;
    height: 100vh;
}
.page-article > div:last-of-type{
    margin-bottom: 100px;
}
.page.mosaic {
    overflow: hidden;
}
.page-timeline{
    padding: 0;
    height: 100%;
    width: 100vw;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
}

.fadeIn{
    animation: fadeIn 1s forwards ease-in-out 0ms;
}
.fadeOut{
    animation: fadeOut 1s forwards ease-in-out 0ms;
}
.fastFadeOut{
    animation: fadeOut 300ms forwards ease-in-out 0ms;
}
.fadeInImage{
    animation: fadeIn 500ms forwards ease-in-out 0ms;
}
.slideOut{
    animation: slideOut 300ms forwards ease-in-out 0ms;
}
.x1.y1{
    display: block;
    transform-origin: left top;
    animation: zoom 400ms ease-out forwards 0ms;
}
.x1.y0{
    display: block;
    transform-origin: left bottom;
    animation: zoomTopRight 400ms ease-out forwards 0ms;
}
.x0.y1{
    display: block;
    transform-origin: right top;
    animation: zoomBottomLeft 400ms ease-out forwards 0ms;
}
.x0.y0{
    display: block;
    transform-origin: right bottom;
    animation: zoomTopLeft 400ms ease-out forwards 0ms;
}
.focus{
    z-index: 10!important;
}
.videoFade{
    animation: videoFade 500ms ease-out forwards 0s;
}

.filter-to-top:after{
    background: linear-gradient(to top, rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%);
}
@keyframes fade{
    0% { opacity: 0}
    100% { opacity: 1}
}
@keyframes fadeIn{
    0%{opacity: 0}
    100%{opacity:1}
}
@keyframes fadeOut{
    0%{opacity: 1}
    100%{opacity:0}
}
@keyframes zoom{
    0% { transform: scale(0); opacity: 0;}
    100% { transform: scale(1); opacity: 1;}
}
@keyframes zoomTopRight{
    0% { transform: scale(0); opacity: 0;}
    100% { transform: scale(1); opacity: 1;}
}
@keyframes zoomTopLeft{
    0% { transform: scale(0); opacity: 0;}
    100% { transform: scale(1); opacity: 1}
}
@keyframes zoomBottomLeft{
    0% { transform: scale(0); opacity: 0;}
    100% { transform: scale(1); opacity: 1}
}
@keyframes videoFade{
    0% {transform: translateY(0%); opacity: 1}
    100% { transform: translateY(100%); opacity: 0}
}
@keyframes slideOut{
    0% { transform: translateX(0%)}
    100% { transform: translate(100%)}
}
@keyframes activeTransition{
    0% {
        filter: brightness(1.5);
        -webkit-filter: brightness(1.5);
    }
    100% {
        filter: brightness(1.0);
        -webkit-filter: brightness(1.0);
    }
}
