.container{
    display: flex;
    padding: 0 var(--standard-hmargin);
}
.item{
   margin: 0 calc(12rem / 29);
}
.item:active{
    filter: brightness(1.2);
}
