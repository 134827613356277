.container{
    position: relative;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 29px;
    display: flex;
    flex-direction: column;
}
.title{
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 17px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.desc{
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    opacity: 0.7;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.credits{
    margin-top: 6px;
    font-size: 14px;
    font-weight: 300;
    opacity: 0.7;
}
.format_overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 4;
}
.format_overlay_text{
    position: absolute;
    bottom: 5%;
    left: 50%;
    color: var(--theme-textColor);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    transform: translateX(-50%);
}