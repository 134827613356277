.article-item {
    position: relative;
    flex: 1;
    min-width: calc(33% - calc(var(--list-spacing) / 3));
    max-width: calc(33% - calc(var(--list-spacing) / 3));
    border-radius: calc(5rem / 29);
    cursor: pointer;
}
.article-item:nth-child(3n+1){
    scroll-snap-align: start;
}
.article-item:active{
    filter: brightness(1.6);
}
.article-item:not(:last-child){
    margin-right: var(--list-spacing);
}
.article-item img{
    width: 100%;
    height: 100%;
    border-radius: calc(5rem / 29);
    object-fit: cover;
}
.article-item:before{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    content: "";
    border-radius: calc(5rem / 29);
    background: transparent linear-gradient(to bottom, rgba(0,0,0,0) 50%,rgba(0,0,0,1) 100%);
}
.article-item-text {
    position: absolute;
    /** text-shadow: 2px 2px rgba(0,0,0,0.5); */
    bottom: calc(40rem / 29);
    left: calc(40rem / 29);
    right: calc(40rem / 29);
    color: var(--theme-textColor2);
    text-shadow: 0 0 5px #000;

}
.article-item-text a {
    text-decoration: none;
}
.article-item-text-desc {
    margin-top:  calc(23rem / 29);
    font-weight: 700;
    font-size: calc(28rem / 29);
    line-height: calc(32rem / 29);
    letter-spacing: 0;

}
.article-item-text-credit {
    font-size: calc(24rem / 29);
    letter-spacing: -0.07px;
    line-height: calc(17rem / 29);
    font-weight: 300;
}
.article-item-text-credit b{
    font-weight: 700;
}
.article-item-text-button {
    margin-top: calc(40rem / 29);
    text-align: center;
    text-transform: uppercase;
    font-size: calc(12rem / 29);
    line-height: calc(15rem / 29);
    letter-spacing: calc(1rem / 29);
    font-weight: 700;
    padding: calc(17rem / 29);
    border-radius: calc(100rem / 29);
    background-color: var(--theme-detailColor);
    color: var(--theme-textColor2);
}

