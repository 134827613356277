.container {
    /* padding: 100px var(--standard-hmargin); */
    padding: calc(45rem / 29) 0;
    color: var(--theme-textColor2);
    overflow-x: auto;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.container::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.container > div:not(.container_image){
    margin-left: var(--standard-hmargin);
    margin-right: var(--standard-hmargin);
}
.item_text {
    padding: 0 var(--standard-h-indent-padding);
    line-height: calc(34rem / 29);
    font-size: calc(20rem / 29);
    letter-spacing: 0;
    font-weight: 300;
}
.item_video {
    position: relative;
    display: flex;
    flex: 1 1;
}
.item_video::before{
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
}
.item_audio {
    /*margin: 50px 150px;*/
    margin: calc(50rem / 29) calc(150rem / 29);
}
.container_image{
    display: flex;
    flex-wrap: nowrap;
}
.container_image_solo{
    display: flex;
    justify-content: center;
}
.container_image::-webkit-scrollbar{
    display: none;
}
.item_image {
    position: relative;
    height: fit-content;
}
.item_image img{
    height: calc(650rem / 29);
    width: fit-content;
    max-width: 80vw;
}
.item_image:last-of-type{
    padding-right: var(--standard-hmargin);
}
.item_image:first-of-type{
    margin-left: var(--standard-hmargin);
}
.item_image:active{
    filter: brightness(1.2);
}
.item_image:only-child.video {
    width: 100%;
    height: auto;
    object-fit: contain;
}
.item_image:not(:first-child), .item_video:not(:first-child) {
    /*margin-left: 30px;*/
    margin-left: calc(30rem / 29);
}
