.container {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    /*margin-top: 88px;
    padding-bottom: 60px;*/
    height: 100%;
    margin-top: 8.6vh;
    padding-bottom: 5.9vh;
}

.container::-webkit-scrollbar{
    -webkit-appearance: none;
    background-color: rgba(255,255,255,0);
    height: calc(calc(var(--scrollbar-padding) * 2) + var(--scrollbar-height))
}

.container::-webkit-scrollbar-track,
.container::-webkit-scrollbar-thumb{
    border-top: 16px solid rgba(255,255,255,0);
    border-bottom: 16px solid rgba(255,255,255,0);
    background-clip: padding-box;
}
.container::-webkit-scrollbar-track{
    background-color: var(--theme-bgColor2);
}
.container::-webkit-scrollbar-thumb{
    background-color: var(--theme-textColor2);
}
.container::-webkit-scrollbar-thumb:hover{
    border: var(--scrollbar-padding) solid rgba(255,255,255,0);
}
