.container{
 position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    touch-action: pan-y;
}
.parallax_container{
    position: absolute;
    height: 100%;
    width: 100%;
}
.bg_parallax, .bg_normal {
    position: absolute;
    height:100%;
    width: 100%;
}
.bg_parallax{
    opacity: 0.5;
    z-index: 1;
}
.bg_parallax img{
    width: 200%
}
.bg_normal img{
    width: 150%;
}
.header{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: var(--standard-vmargin) var(--standard-hmargin) 0 var(--standard-hmargin);
}
.slider_container{
    position: relative;
    display: flex;

    flex-direction: column;
    z-index: 3;
}
