.container{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    color: white;
    z-index: 20;
}
.content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    width: 450px;
    font-size: 24px;
    border-radius: 3px;
    background-color: #FFF;
    color: var(--theme-bgColor);
}
.header{
    flex: 0 1;
    font-weight: 700;
    margin: 10px;
    padding: 20px 0;
    text-align: center;
    border-bottom: 1px solid #CCC;
}
.info{
    margin: 0 30px;
    font-weight: 700;
}
.button_container{
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 30px;
}
.button_container:active{
    filter: brightness(1.2);
}