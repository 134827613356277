.container{
    position: relative;
    display: flex;
    /*margin-top: 27vh;*/
    margin-bottom: 9vh;
    padding-top: 18vh;
    padding-bottom: 18vh;
    overflow-x: auto;
    background-color: transparent;
    z-index: 3;
}
.item {
    min-width: 25vw;
    margin-right: 120px;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 5px 5px;

}
.item_missing_image{
    justify-content: center;
}
.item > div{
    height: unset;
}
.item:active{
    filter: brightness(1.2);
}
.item:active h3{
    text-shadow: 0 0 5px #000;
}
.item:first-of-type{
    margin-left: 120px;
}
.item:last-of-type{
    padding-right: 120px;
}
.item h3 {
    margin: var(--pixels-15) 0 0;
    /*padding: 10px 18px;*/
    padding: 0.35rem 0.6rem;
    color: var(--theme-textColor2);
    background: rgba(0,0,0,0.4);
    font-weight: 700;
    font-size: var(--pixels-28);
    /*line-height: 40px;*/
    line-height: 1.38rem;
    letter-spacing: -0.17px;
    text-shadow: 4px 4px 90px #000000;
    opacity: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 4em;
    border-radius: 1px;

}
.item_missing_image h3{
    margin-bottom: 65px;
}
.item img {
    height: 22vh;
    width: 25vw;
    box-shadow: 0 10px 6px -6px rgba(0, 0, 0, .3);
}
.container::after{
    content: '';
    display: block;
    width: 100%;
    /*height: 50px;*/

    height: calc(50rem / 29);
}
.container::-webkit-scrollbar{
    -webkit-appearance: none;
    background-color: rgba(255,255,255,0);
    height: calc(calc(calc(16rem / 29) * 2) + calc(4rem / 29));
    width: calc(240rem / 29);
}

.container::-webkit-scrollbar-track,
.container::-webkit-scrollbar-thumb{
    background-clip: padding-box;
    border-top: calc(16rem / 29) solid rgba(255,255,255,0);
    border-bottom: calc(16rem / 29) solid rgba(255,0,255,0);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    border-left: 5px solid rgba(0,0,0,0);
    border-right: 5px solid rgba(0,0,0,0);
    margin-left: 120px;
    margin-right: 120px;
}
.container::-webkit-scrollbar-track{
    background-color: rgba(255,255,255,0);

}
.container::-webkit-scrollbar-thumb{
    background-color: var(--theme-textColor2);
}
.container::-webkit-scrollbar-thumb:hover{
    border: var(--scrollbar-padding) solid rgba(255,255,255,0);
}

@media only screen and (orientation: portrait){
    .item{
        min-width: 33vw;
    }
}