.audioplayer {
    background-color: var(--theme-bgColor2);
    /*border-radius: 6px;*/
    border-radius: calc(6rem / 29);
}
.audioplayer-content-container {
    /*padding: 30px 30px;*/
    padding: calc(30rem / 29);
}
.audioplayer-content-title {
    /*margin: 5px 0;*/
    margin: calc(5rem / 29) 0;
    font-weight: 300;
    /*font-size: 21px;*/
    font-size: calc(21rem / 29);
    /*letter-spacing: -0.15px;*/
    letter-spacing: calc(-0.15rem / 29);

}
.audioplayer-content-titledesc {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.audioplayer-content-titledesc > svg{
    flex: 0 0 auto;
    margin-right: 8px;
}
.audioplayer-content-titledesc > p {
    margin: 0;
    flex: 1;
    /*font-size: 14px;*/
    font-size: calc(14rem / 29);
    opacity: 0.6;
}
.audioplayer-content-interface {
    display: flex;
    flex-direction: row;
    /*margin: 20px 0;*/
    margin: calc(20rem / 29) 0;
}
.audioplayer-content-desc {
   /* margin: 15px 0;
    line-height: 1.5em;*/
    margin: calc(15rem / 29) 0;
    line-height: calc(1.5rem / 29);
}
.audioplayer-content-play {
    position: relative;
    /*padding: 25px;
    border-radius: 100px;*/
    padding: calc(25rem / 29);
    border-radius: 50%;
    background-color: var(--theme-detailColor);
}
.audioplayer-content-play:active{
    filter: brightness(1.2);
}
.audioplayer-content-play svg {
    position: absolute;
    width: calc(24rem / 29);
    height: calc(24rem / 29);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.audioplayer-content-timeline {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-size: calc(24rem / 29);
}
.audioplayer-content-starttime {
    margin: auto 10px;
}
.audioplayer-content-progressbar {
    flex: 1 1 auto;
    /*height: 7px;
    border-radius: 7px;*/
    height: calc(7rem / 29);
    border-radius: calc(7rem / 29);
    background-color: #777;
}
.audioplayer-content-endtime {
    margin: auto 10px;
}

.audioplayer-spacer {
    border-top: 1px solid #D8D8D8;
    opacity: 0.1;
}
