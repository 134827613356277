.menu {
    position: fixed;
    top: 0;
    left: 50%;
    height: 100%;
    width: 100%;
    z-index: 10;
    overflow-x: hidden;
    overflow-y: hidden;
    transform: translateX(-50%);
}
.menu-filter {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    z-index: 2;
    animation: fadeIn 300ms ease-out 0ms;
}
.menu-content {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*width: 480px;*/
    width: 35.15vw;
    height: 100vh;
    background-color: var(--theme-bgColor);
    color: var(--theme-textColor);
    top: 0;
    right: 0;
    z-index: 3;
    -webkit-animation: slide 300ms 0s 1 ease-out;
    -moz-animation: slide 300ms 0s 1 ease-out;
    -o-animation: slide 300ms 0s 1 ease-out;
    animation: slide 300ms 0s 1 ease-out;
}
.menu-items{
    display: flex;
    flex-direction: column;
    padding: var(--menu-vmargin) var(--menu-hmargin) 0 var(--menu-hmargin);
}
.menu-item{
    display: flex;
    align-items: center;
    color: var(--theme-textColor);
}
.menu-item:not(:last-of-type){
    margin-bottom: 17px;
}
.menu-item svg {
    /*margin-right: 34px;
    width: 25px;
    height: 25px;*/
    margin-right: calc(34rem / 29);
    width: calc(25rem / 29);
    height: calc(25rem / 29);
    fill: var(--theme-textColor);
}
.menu-item-selected{
    color: var(--theme-detailColor)
}
.menu-item-selected svg{
    fill: var(--theme-detailColor)
}
.menu-item-text{
    text-decoration: none;
    font-weight: 300;
    /*font-size: 22px;
    line-height: 36px;*/
    font-size: calc(22rem / 29);
    line-height: calc(36rem / 29);
}
.menu-item-text:active{
    animation: activeTransition 1000ms ease-in-out 0ms;
}
.menu-footer {
    padding: 0 77px 34px var(--menu-hmargin);
}
.menu-footer-lang {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 33px;
}
.menu-footer-lang-item {
    font-weight: 300;
    /*font-size: 22px;
    line-height: 36px;*/
    font-size: calc(22rem / 29);
    line-height: calc(36rem / 29);
    letter-spacing: 0;
    color: var(--theme-textColor);
    cursor: pointer;
}
.menu-footer-lang-item:active{
    animation: activeTransition 1000ms ease-in-out 0ms;
}
.menu-footer-lang-item:nth-child(n+4){
    justify-content: end;
}
.menu-footer-lang-item:not(:last-of-type){
    /* margin-bottom: 20px; */
    margin-bottom: calc(20rem / 29);
}
.menu-footer-brand {
    display: flex;
    justify-content: center;
    border-top: 1px solid #464646;
    padding-top: 31px;
}
.menu-footer-brand h5 {
    color: var(--theme-textColor);
    margin: 0;
    padding: 0;
    line-height: 31px;
    font-size: 14px;
    font-weight: 300;
}
.menu-footer-brand h6 {
    margin: 0 0 0 5px;
    align-self: flex-end;
    padding: 0;
    font-size: 10px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0;
    color: var(--theme-textColor);
    opacity: 0.49;
}
.menu-button{
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 var(--menu-hmargin);
}
.menu-button-text{
    margin-left: 25px;
    font-size: calc(22rem / 29);
}
@keyframes slide {
    0% {transform: translateX(400px)}
    100%{transform: translateX(0px)}
}