.container {
    position: absolute;
    z-index: 6;
    pointer-events: none;
    width: 1px;
    height: 1px;
}
.label{
    border-radius:10px;
    height: 60px;
    width: 60px;
    transform-origin: center bottom;
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, .2));
    pointer-events: all;
}
.label > * {
    pointer-events: none;
}
.label:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    padding: 20px;
}
.infobox{
    position: absolute;
    display: none;
    width: calc(300rem / 29);
    border-radius: 5px;
    background-color: #FFF;
    box-shadow: 0 20px 50px #00000033;
    pointer-events: auto;
}
.infobox:active{
    filter: brightness(1.2);
}
.infobox img{
    height: calc(220rem / 29);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width:100%;
    object-fit: cover;
}
.infobox_arrow{
    position: absolute;
    height: 0;
    width: 0;
    border-left: var(--mapimage-arrow-size) solid transparent;
    border-right: var(--mapimage-arrow-size) solid transparent;
    border-bottom:  calc(var(--mapimage-arrow-size) * 2) solid #FFF;
    transform: translate(-100%, -100%) rotate(-90deg);
    z-index: -1;
}
.title, .desc{
    padding-left: calc(30rem / 29);
    padding-right: calc(30rem / 29);
}
.title{
    margin-top: calc(29rem / 29);
    margin-bottom: calc(21rem / 29);
    text-align: left;
    font-size: calc(24rem / 29);
    line-height: calc(30rem / 29);
    font-weight: 700;
    letter-spacing: -0.13px;
    color: #1C1A1A;
    opacity: 1;
}
.desc {
    margin-top: calc(21rem / 29);
    margin-bottom: calc(37rem / 29);
    text-align: left;
    font-size: calc(16rem / 29);
    line-height: calc(24rem / 29);
    font-weight: 400;
    letter-spacing: -0.08px;
    color: #1C1A1A;
    opacity: 1;
}
