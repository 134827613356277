.article-header-row {
    position: relative;
    display: flex;
    align-items: center;
    pointer-events: none;
}
.article-header-row svg {
    color: var(--theme-textColor);
    fill: var(--theme-textColor);
    pointer-events: all;
}
.article-header {
    flex: 1;
    margin: 0;
    padding: 0 calc(200rem / 29) 0 calc(50rem / 29);
    font-weight: 700;
    /*line-height: 52px;*/
    line-height: calc(52rem / 29);

    /*font-size: 40px;*/
    /*font-size: var(--pixels-40);*/
    font-size: calc(40rem / 29);
    letter-spacing: -0.25px;
    text-shadow: 1px 1px 5px rgba(0,0,0,.5);
}
.article-button-back {
    height: 34px;
    color: var(--theme-textColor);
    filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, .5));
}
.article-button-back:active{
    animation: activeTransition 1000ms ease-in-out 0ms;
}
.article-button-back:after{
    content: '';
   position: absolute;
    height: 150px;
    width: 150px;
}
.article-menu {
    margin-top: 10px;
    animation: activeTransition 1000ms ease-in-out 0ms;
    filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, .5));
}
.article-button-back, .article-header-row h1, .article-menu {
    z-index: 2;
    cursor: pointer;
}
.article-menu:active{
    filter: brightness(1.2);
}
.article-filter {
    position: fixed;
    width: 100%;
    top: 0;
    left: 50%;
    height: 150px;
    background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0,rgba(0,0,0,0) 100%);
    z-index: 1;
    transform: translateX(-50%);
    pointer-events: none;

}