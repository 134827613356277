.container{
 position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #363636;
    z-index:4;
    touch-action: pan-x;
}
.label {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 10;
}