.imageinfobox {
    position: fixed;
    top: 0;
    right: 0;
    width: 400px;
    height: 100%;
    z-index: 4;
    -webkit-animation: slide 300ms 0s 1 ease-out;
    -moz-animation: slide 300ms 0s 1 ease-out;
    -o-animation: slide 300ms 0s 1 ease-out;
    animation: slide 300ms 0s 1 ease-out;
}
.imageinfobox-filter {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0);
    z-index: 3;
}
.imageinfobox-content {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    background-color: rgba(33, 33, 33, 0.8);
}
.imageinfobox-content-container {
    flex: 1;
    margin: calc(150rem / 29) calc(50rem / 29) calc(90rem / 29) calc(50rem / 29);
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
}
.imageinfobox-content-item{
    text-align: right;
    position: relative;
    font-size: calc(16rem / 29);
}
.imageinfobox-content-item:first-child{
    margin-bottom: calc(100rem / 29);
}
.imageinfobox-content-item h3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.imageinfobox-content-item p {
    opacity: 0.9;
    font-style: italic;
    line-height: 1.5em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
}
.imageinfobox-content-flick {
    position: absolute;
    left: -50px;
    top: -36px;
    width: 50px;
    height: 50px;
    border-top: 14px solid #d60039;
    border-left: 14px solid #d60039;
    transform: translateX(-14px);
}

.imageinfobox-credit{
    display: flex;
    justify-content: flex-end;
    margin-top: calc(100rem / 29);
}
@keyframes slide {
 0% {-webkit-transform: translateX(400px);
     transform: translateX(400px)}
    100%{-webkit-transform: translateX(0px);
        transform: translateX(0px)}
}