.list_container{
    list-style-type: none;
    display: flex;
    justify-content: center;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.nopres{
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: 300;
    line-height: 5em;
}