.article-img {
    position: relative;
    margin-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    height: calc(440rem / 29);
    min-height: calc(440rem / 29);
}
.article-img img{
    object-fit: cover;
}
.article-header-container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: var(--standard-vmargin) var(--standard-hmargin) 0 var(--standard-hmargin);
}
.article-img::before {
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
}
.article-block-text {
    margin: 40px 150px;
    font-size: 0.8em;
    line-height: 2em;
    letter-spacing: 0.05em;
}
.article-block-text p {
    margin-top: 40px;
}
.article-block-img, .article-block-video {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
}
.article-block-img a:not(:first-child), .article-block-video a:not(:first-child){
    margin-left: 30px;
}
.article-block-img-item, .article-block-video-item {
    position: relative;
    display: flex;
    flex: 1;
}

.article-block-img-item img{
    flex: 1;
    height: 500px;
    width: auto;
    object-fit: cover;

}
.article-block-video-item iframe {
    margin: 0;
    padding: 0;
    border: none;
    height: 100%;
    width: auto;
}
.article-block-audio {
    margin: 40px 150px;
}

.article-block-img2 {
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
    justify-content: space-between;
}
.article-block-img2 a {
    margin: 4px auto;
    flex: 1;
}
.article-block-img2-item img{
    height: 29vw;
    width: 29vw;
    object-fit: cover;
}
.article-byline{
    position: absolute;
    left: 177px;
    top: 193px;
    z-index:2;
    text-shadow: 0 0 5px #000;
}
.article-pageNav{
    position: relative;
    margin: calc(76rem / 29) 0 0 0;
    padding-bottom: calc(29rem / 29);
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
}
.error-container{
    position: fixed;
    top: 0;
    left:0 ;
    right: 0;
    bottom: 0;
    z-index: 10;
}