.container{
    position: relative;
    /*height: 7px;*/
    height: calc(7rem / 29);
    flex: 1;
    background: #F1F1F1 0 0 no-repeat padding-box;
    border-radius: 4px;
}
.container:after{
    content: '';
    position: absolute;
    top: -20px;
    height: 47px;
    width: 100%;
}
.filler{
    height: 100%;
    border-radius: inherit;
    transition: width .2s ease-in;
    z-index: 5;
    background-color: var(--theme-detailColor);
}